@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
:root {
  --token-color-border-secondary-default: #405159;
  --token-color-border-secondary-hovered: #2F3C42;
  --token-color-border-secondary-subtle: #C4CFD4;
  --token-color-border-default: #C4CFD4;
  --token-color-border-hovered: #899FA9;
  --token-color-border-primary-default: #005783;
  --token-color-border-primary-hovered: #003A57;
  --token-color-border-primary-subtle: #BEE4F8;
  --token-color-border-success-subtle: #BDE3BB;
  --token-color-border-danger-subtle: #F1C9C9;
  --token-color-border-warning-subtle: #F4D8BE;
  --token-color-border-accent-a-subtle: #DDCFEF;
  --token-color-border-accent-b-subtle: #F4D8BE;
  --token-color-border-accent-c-subtle: #DAF2F2;
  --token-color-deep-sea-blue-100: #F1FBFF;
  --token-color-deep-sea-blue-200: #D9EAF1;
  --token-color-deep-sea-blue-300: #C1D9E3;
  --token-color-deep-sea-blue-400: #91B6C6;
  --token-color-deep-sea-blue-500: #6094AA;
  --token-color-deep-sea-blue-600: #30718D;
  --token-color-deep-sea-blue-700: #004F71;
  --token-color-deep-sea-blue-800: #003F5A;
  --token-color-deep-sea-blue-900: #002F44;
  --token-color-deep-sea-blue-1000: #00202D;
  --token-color-deep-sea-blue-1100: #001017;
  --token-color-blue-100: #EEF9FF;
  --token-color-blue-200: #D6EFFB;
  --token-color-blue-300: #BEE4F8;
  --token-color-blue-400: #8FCFF0;
  --token-color-blue-500: #5FBBE9;
  --token-color-blue-600: #30A6E1;
  --token-color-blue-700: #0091DA;
  --token-color-blue-800: #0074AE;
  --token-color-blue-900: #005783;
  --token-color-blue-1000: #003A57;
  --token-color-blue-1100: #001D2C;
  --token-color-olive-100: #FDFFE5;
  --token-color-olive-200: #F7FBCE;
  --token-color-olive-300: #F2F7B7;
  --token-color-olive-400: #E6EF89;
  --token-color-olive-500: #DBE65C;
  --token-color-olive-600: #CFDE2E;
  --token-color-olive-700: #C4D600;
  --token-color-olive-800: #9DAB00;
  --token-color-olive-900: #768000;
  --token-color-olive-1000: #4E5600;
  --token-color-olive-1100: #272B00;
  --token-color-red-100: #FEF5F5;
  --token-color-red-200: #F8DFDF;
  --token-color-red-300: #F1C9C9;
  --token-color-red-400: #E59E9E;
  --token-color-red-500: #D87272;
  --token-color-red-600: #CC4747;
  --token-color-red-700: #BF1B1B;
  --token-color-red-800: #991616;
  --token-color-red-900: #731010;
  --token-color-red-1000: #4C0B0B;
  --token-color-red-1100: #260505;
  --token-color-yellow-100: #FFFAEC;
  --token-color-yellow-200: #FEEEC4;
  --token-color-yellow-300: #FDE29C;
  --token-color-yellow-400: #FDD774;
  --token-color-yellow-500: #FCCB4C;
  --token-color-yellow-600: #FBBF24;
  --token-color-yellow-700: #D29E1E;
  --token-color-yellow-800: #A97D18;
  --token-color-yellow-900: #7F5D12;
  --token-color-yellow-1000: #563C0C;
  --token-color-yellow-1100: #2D1B06;
  --token-color-green-100: #E8FDE7;
  --token-color-green-200: #D2F0D1;
  --token-color-green-300: #BDE3BB;
  --token-color-green-400: #91C990;
  --token-color-green-500: #66B064;
  --token-color-green-600: #3A9639;
  --token-color-green-700: #0F7C0D;
  --token-color-green-800: #0C630A;
  --token-color-green-900: #094A08;
  --token-color-green-1000: #063205;
  --token-color-green-1100: #031903;
  --token-color-orange-100: #FCF2E8;
  --token-color-orange-200: #F8E5D3;
  --token-color-orange-300: #F4D8BE;
  --token-color-orange-400: #EBBE94;
  --token-color-orange-500: #E1A36B;
  --token-color-orange-600: #D88941;
  --token-color-orange-700: #CF6F17;
  --token-color-orange-800: #A65912;
  --token-color-orange-900: #7C430E;
  --token-color-orange-1000: #532C09;
  --token-color-orange-1100: #291605;
  --token-color-purple-100: #F4F0FA;
  --token-color-purple-200: #E8E0F5;
  --token-color-purple-300: #DDCFEF;
  --token-color-purple-400: #C5AEE4;
  --token-color-purple-500: #AE8ED9;
  --token-color-purple-600: #966DCE;
  --token-color-purple-700: #7F4CC3;
  --token-color-purple-800: #6A3FA4;
  --token-color-purple-900: #563385;
  --token-color-purple-1000: #412665;
  --token-color-purple-1100: #2D1A46;
  --token-color-pink-100: #FEF6FC;
  --token-color-pink-200: #F7DFF1;
  --token-color-pink-300: #F0C9E5;
  --token-color-pink-400: #E29CCE;
  --token-color-pink-500: #D46EB8;
  --token-color-pink-600: #C641A1;
  --token-color-pink-700: #B8148A;
  --token-color-pink-800: #93106E;
  --token-color-pink-900: #6E0C53;
  --token-color-pink-1000: #4A0837;
  --token-color-pink-1100: #25041C;
  --token-color-teal-100: #EDFAFA;
  --token-color-teal-200: #DAF2F2;
  --token-color-teal-300: #ABDBDB;
  --token-color-teal-400: #77C7C7;
  --token-color-teal-500: #0F9494;
  --token-color-teal-600: #108080;
  --token-color-teal-700: #0D6666;
  --token-color-teal-800: #0B5959;
  --token-color-teal-900: #094D4D;
  --token-color-teal-1000: #083D3D;
  --token-color-teal-1100: #052626;
  --token-color-neutral-100: #F3F5F6;
  --token-color-neutral-200: #E7ECEE;
  --token-color-neutral-300: #C4CFD4;
  --token-color-neutral-400: #A6B7BF;
  --token-color-neutral-500: #899FA9;
  --token-color-neutral-600: #6B8894;
  --token-color-neutral-700: #566C76;
  --token-color-neutral-800: #405159;
  --token-color-neutral-900: #2F3C42;
  --token-color-neutral-1000: #222B2F;
  --token-color-neutral-1100: #151B1E;
  --token-color-white: #ffffff;
  --token-color-text-disabled: #566C76;
  --token-color-text-default: #001D2C;
  --token-color-text-heading-default: #003A57;
  --token-color-text-primary-default: #005783;
  --token-color-text-primary-hovered: #003A57;
  --token-color-text-secondary: #405159;
  --token-color-text-inverse: #ffffff;
  --token-color-text-danger: #991616;
  --token-color-text-success: #0C630A;
  --token-color-text-warning-on-bold: #001D2C;
  --token-color-text-warning-on-subtle: #7C430E;
  --token-color-background-danger-subtle: #FEF5F5;
  --token-color-background-danger-bold-default: #991616;
  --token-color-background-danger-bold-hovered: #731010;
  --token-color-background-success-subtle: #E8FDE7;
  --token-color-background-success-bold-default: #0C630A;
  --token-color-background-success-bold-hovered: #094A08;
  --token-color-background-secondary-subtle: #F3F5F6;
  --token-color-background-secondary-bold-default: #405159;
  --token-color-background-secondary-bold-hovered: #2F3C42;
  --token-color-background-secondary-subdued-default: #E7ECEE;
  --token-color-background-secondary-subdued-hovered: #C4CFD4;
  --token-color-background-primary-subtle: #EEF9FF;
  --token-color-background-primary-dark: #001D2C;
  --token-color-background-primary-bold-default: #005783;
  --token-color-background-primary-bold-hovered: #003A57;
  --token-color-background-warning-subtle: #FFFAEC;
  --token-color-background-warning-bold-default: #D29E1E;
  --token-color-background-warning-bold-hovered: #A97D18;
  --token-color-background-inverse-default: #ffffff;
  --token-color-icon-fill-default: #001D2C;
  --token-color-icon-fill-primary-bold: #0091DA;
  --token-color-icon-fill-primary-default: #005783;
  --token-color-icon-fill-primary-hovered: #003A57;
  --token-color-icon-fill-secondary: #405159;
  --token-color-icon-fill-inverse: #ffffff;
  --token-color-icon-fill-danger: #991616;
  --token-color-icon-fill-success: #0C630A;
  --token-color-icon-fill-warning-on-subtle: #7C430E;
  --token-color-icon-fill-warning-on-bold: #001D2C;
  --token-color-outline-focused-on-dark: #ffeb3b;
  --token-color-outline-focused-on-light: #001D2C;
  --token-color-primary-default: #005783;
  --token-color-primary-hovered: #003A57;
  --token-color-primary-dark: #001D2C;
  --token-color-secondary-default: #405159;
  --token-color-secondary-hovered: #2F3C42;
  --token-color-secondary-on-dark-default: #C1D9E3;
  --token-color-danger-default: #991616;
  --token-color-danger-hovered: #731010;
  --token-color-success-default: #0C630A;
  --token-color-success-hovered: #094A08;
  --token-color-warning-default: #D29E1E;
  --token-color-warning-hovered: #A97D18;
  --token-color-accent-a-subtle: #F4F0FA;
  --token-color-accent-a-default: #7F4CC3;
  --token-color-accent-a-hovered: #6A3FA4;
  --token-color-accent-a-dark: #412665;
  --token-color-accent-b-subtle: #FCF2E8;
  --token-color-accent-b-default: #CF6F17;
  --token-color-accent-b-hovered: #A65912;
  --token-color-accent-b-dark: #532C09;
  --token-color-accent-c-subtle: #EDFAFA;
  --token-color-accent-c-default: #108080;
  --token-color-accent-c-hovered: #0D6666;
  --token-color-accent-c-dark: #083D3D;
  --token-action-button-border-radius-default: 4px;
  --token-action-button-border-secondary-default: 1px solid #405159;
  --token-action-button-border-secondary-hovered: 1px solid #2F3C42;
  --token-action-button-border-secondary-on-dark-default: 1px solid #C1D9E3;
  --token-action-button-border-tag-default: 1px solid #405159;
  --token-action-button-border-tag-hovered: 1px solid #2F3C42;
  --token-action-button-color-text-secondary-on-dark-default: #C1D9E3;
  --token-action-button-color-text-secondary-on-dark-hovered: #001D2C;
  --token-action-button-color-icon-fill-secondary-on-dark-default: #C1D9E3;
  --token-action-button-color-icon-fill-secondary-on-dark-hovered: #001D2C;
  --token-action-button-color-background-secondary-on-dark-default: transparent;
  --token-action-button-color-background-secondary-on-dark-hovered: #C1D9E3;
  --token-scale: 4px;
  --token-border-radius-default: 16px;
  --token-border-radius-xxs: 2px;
  --token-border-radius-xs: 4px;
  --token-border-radius-sm: 8px;
  --token-border-radius-md: 16px;
  --token-border-radius-lg: 24px;
  --token-border-radius-xl: 32px;
  --token-border-width-default: 1px;
  --token-border-width-2x: 2px;
  --token-border-width-3x: 3px;
  --token-border-width-4x: 4px;
  --token-spacing-default: 16px; /* Default spacing value */
  --token-spacing-4xs: 2px;
  --token-spacing-3xs: 4px;
  --token-spacing-2xs: 6px;
  --token-spacing-xs: 8px;
  --token-spacing-sm: 12px;
  --token-spacing-md: 16px;
  --token-spacing-lg: 20px;
  --token-spacing-xl: 24px;
  --token-spacing-2xl: 32px;
  --token-spacing-3xl: 40px;
  --token-spacing-4xl: 48px;
  --token-spacing-5xl: 56px;
  --token-spacing-6xl: 64px;
  --token-spacing-inset-default: 16px 16px 16px 16px; /* Equal spacing on all sides at the default (md) size */
  --token-spacing-inset-3xs: 4px 4px 4px 4px;
  --token-spacing-inset-2xs: 6px 6px 6px 6px;
  --token-spacing-inset-xs: 8px 8px 8px 8px;
  --token-spacing-inset-sm: 12px 12px 12px 12px;
  --token-spacing-inset-md: 16px 16px 16px 16px;
  --token-spacing-inset-lg: 20px 20px 20px 20px;
  --token-spacing-inset-xl: 24px 24px 24px 24px;
  --token-spacing-inset-2xl: 32px 32px 32px 32px;
  --token-spacing-inset-3xl: 40px 40px 40px 40px;
  --token-spacing-inset-4xl: 48px 48px 48px 48px;
  --token-spacing-inset-5xl: 56px 56px 56px 56px;
  --token-spacing-inset-6xl: 64px 64px 64px 64px;
  --token-spacing-stack-default: 0 0 16px 0; /* Default (md) bottom spacing */
  --token-spacing-stack-4xs: 0 0 4px 0;
  --token-spacing-stack-3xs: 0 0 4px 0;
  --token-spacing-stack-2xs: 0 0 6px 0;
  --token-spacing-stack-xs: 0 0 8px 0;
  --token-spacing-stack-sm: 0 0 12px 0;
  --token-spacing-stack-md: 0 0 16px 0;
  --token-spacing-stack-lg: 0 0 20px 0;
  --token-spacing-stack-xl: 0 0 24px 0;
  --token-spacing-stack-2xl: 0 0 32px 0;
  --token-spacing-stack-3xl: 0 0 40px 0;
  --token-spacing-inset-squish-default: 8px 16px; /* Horizontal padding at the default (md) size Vertical padding at xs size */
  --token-spacing-inset-squish-3xs: 2px 4px;
  --token-spacing-inset-squish-2xs: 4px 6px;
  --token-spacing-inset-squish-xs: 4px 8px;
  --token-spacing-inset-squish-sm: 6px 12px;
  --token-spacing-inset-squish-md: 8px 16px;
  --token-spacing-inset-squish-lg: 12px 20px;
  --token-spacing-inset-squish-xl: 12px 24px;
  --token-spacing-inset-squish-2xl: 16px 32px;
  --token-spacing-inset-squish-3xl: 20px 40px;
  --token-spacing-inset-stretch-default: 16px 8px; /* Vertical padding at the default (md) size Horizontal padding at xs size */
  --token-spacing-inset-stretch-3xs: 4px 2px;
  --token-spacing-inset-stretch-2xs: 6px 4px;
  --token-spacing-inset-stretch-xs: 8px 4px;
  --token-spacing-inset-stretch-sm: 12px 6px;
  --token-spacing-inset-stretch-md: 16px 8px;
  --token-spacing-inset-stretch-lg: 20px 12px;
  --token-spacing-inset-stretch-xl: 24px 12px;
  --token-spacing-inset-stretch-2xl: 32px 16px;
  --token-spacing-inset-stretch-3xl: 40px 20px;
  --token-spacing-inline-default: 0 16px 0 0; /* Default (md) spacing on the right */
  --token-spacing-inline-4xs: 0 2px 0 0;
  --token-spacing-inline-3xs: 0 4px 0 0;
  --token-spacing-inline-2xs: 0 6px 0 0;
  --token-spacing-inline-xs: 0 8px 0 0;
  --token-spacing-inline-sm: 0 12px 0 0;
  --token-spacing-inline-md: 0 16px 0 0;
  --token-spacing-inline-lg: 0 20px 0 0;
  --token-spacing-inline-xl: 0 24px 0 0;
  --token-spacing-inline-2xl: 0 32px 0 0;
  --token-spacing-inline-3xl: 0 40px 0 0;
  --token-font-family-sans-serif: "Open Sans";
  --token-font-weight-regular: 400;
  --token-font-weight-semibold: 600;
  --token-font-weight-bold: 700;
  --token-font-size-xs: 12px;
  --token-font-size-sm: 14px;
  --token-font-size-md: 16px;
  --token-font-size-lg: 18px;
  --token-font-size-xl: 24px;
  --token-font-size-2xl: 32px;
  --token-font-size-3xl: 40px;
  --token-text-decoration-none: none;
  --token-text-decoration-underline: underline;
  --token-text-decoration-strike-through: line-through;
  --token-line-height-xs: 16px;
  --token-line-height-sm: 20px;
  --token-line-height-md: 24px;
  --token-line-height-lg: 24px;
  --token-line-height-xl: 32px;
  --token-line-height-2xl: 40px;
  --token-line-height-3xl: 52px;
  --token-typography-body-md-regular: 400 16px/24px "Open Sans";
  --token-typography-body-md-semibold: 600 16px/24px "Open Sans";
  --token-typography-body-md-bold: 700 16px/24px "Open Sans";
  --token-typography-body-sm-regular: 400 14px/20px "Open Sans";
  --token-typography-body-sm-semibold: 600 14px/20px "Open Sans";
  --token-typography-body-sm-bold: 700 14px/20px "Open Sans";
  --token-typography-body-lg-regular: 400 18px/24px "Open Sans";
  --token-typography-body-lg-semibold: 600 18px/24px "Open Sans";
  --token-typography-body-lg-bold: 700 18px/24px "Open Sans";
  --token-typography-body-xs-regular: 400 12px/16px "Open Sans";
  --token-typography-body-xs-semibold: 600 12px/16px "Open Sans";
  --token-typography-body-xs-bold: 700 12px/16px "Open Sans";
  --token-typography-heading-3xl-regular: 400 40px/52px "Open Sans";
  --token-typography-heading-3xl-semibold: 600 40px/52px "Open Sans";
  --token-typography-heading-3xl-bold: 700 40px/52px "Open Sans";
  --token-typography-heading-2xl-regular: 400 32px/40px "Open Sans";
  --token-typography-heading-2xl-semibold: 600 32px/40px "Open Sans";
  --token-typography-heading-2xl-bold: 700 32px/40px "Open Sans";
  --token-typography-heading-xl-regular: 400 24px/32px "Open Sans";
  --token-typography-heading-xl-semibold: 600 24px/32px "Open Sans";
  --token-typography-heading-xl-bold: 700 24px/32px "Open Sans";
  --token-typography-heading-lg-regular: 400 18px/24px "Open Sans";
  --token-typography-heading-lg-semibold: 600 18px/24px "Open Sans";
  --token-typography-heading-lg-bold: 700 18px/24px "Open Sans";
  --token-typography-heading-md-regular: 400 16px/24px "Open Sans";
  --token-typography-heading-md-semibold: 600 16px/24px "Open Sans";
  --token-typography-heading-md-bold: 700 16px/24px "Open Sans";
  --token-typography-heading-sm-regular: 400 14px/20px "Open Sans";
  --token-typography-heading-sm-semibold: 600 14px/20px "Open Sans";
  --token-typography-heading-sm-bold: 700 14px/20px "Open Sans";
  --token-icon-size-default: 18px;
  --token-icon-size-sm: 16px;
  --token-icon-size-md: 18px;
  --token-icon-size-lg: 24px;
  --token-icon-size-xl: 32px;
  --token-icon-size-2xl: 40px;
  --token-elevation-overlay-shadow: 0 8px 12px 0 #001D2C4F, 0 1px 1px 0 #001D2C26; /* Shadow for elements that overlay others such as modals and popovers */
  --token-elevation-raised-shadow: 0 0 1px 0 #001D2C52, 0 3px 3px 0 #001D2C14; /* Shadow for elements that are slightly raised such as cards and tiles */
  --token-elevation-subtle-shadow: 0 1px 2px 0 #001D2C1A; /* Subtle shadow below the element it's applied to. Used for navigation / breadcrumb bar */
  --token-elevation-subtle-shadow-hovered: 0 1px 2px 0 #001D2C1A, 0 4px 8px 0 #001D2C1A; /* Hovered version of the subtle box shadow */
}
body {
  font: var(--token-typography-body-md-regular);
  background-color: var(--token-color-background-secondary-subtle);
}
button {
  font: inherit;
}
html {
  box-sizing: border-box;
  scroll-padding-top: 170px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body {
  margin: 0;
  padding: 0;
}._Stack_1mzit_1 {
  display: flex;
  flex-direction: column;
  gap: var(--stack-gap);
}
._Stack_1mzit_1._Stack--inline_1mzit_6 {
  flex-direction: row;
}
._Stack_1mzit_1._Stack--wrap_1mzit_9 {
  flex-wrap: wrap;
}
._Stack_1mzit_1._Stack--alignItems-center_1mzit_12 {
  align-items: center;
}
._Stack_1mzit_1._Stack--alignItems-end_1mzit_15 {
  align-items: end;
}
._Stack_1mzit_1._Stack--alignItems-start_1mzit_18 {
  align-items: start;
}
._Stack_1mzit_1._Stack--alignItems-baseline_1mzit_21 {
  align-items: baseline;
}
._Stack_1mzit_1._Stack--justifyContent-center_1mzit_24 {
  justify-content: center;
}
._Stack_1mzit_1._Stack--justifyContent-space-around_1mzit_27 {
  justify-content: space-around;
}
._Stack_1mzit_1._Stack--justifyContent-space-between_1mzit_30 {
  justify-content: space-between;
}
._Stack_1mzit_1._Stack--justifyContent-space-evenly_1mzit_33 {
  justify-content: space-evenly;
}
._Stack_1mzit_1._Stack--justifyContent-end_1mzit_36 {
  justify-content: end;
}
._Stack_1mzit_1._Stack--wrap_1mzit_9 {
  flex-wrap: wrap;
}
@media (min-width: 600px) {
  ._Stack_1mzit_1._Stack--wrap_1mzit_9 {
    flex-wrap: initial;
  }
}
._Stack_1mzit_1._Stack--fullHeight_1mzit_47 {
  height: 100%;
}
._Stack_1mzit_1._Stack--fullWidth_1mzit_50 {
  width: 100%;
}._root_i72it_1 {
  margin: 0;
  font-family: var(--token-font-family-sans-serif);
  color: var(--token-color-text-default);
  text-align: left;
  cursor: inherit;
  max-width: 80ch;
}

._headingSm_i72it_10,
._headingMd_i72it_11,
._headingLg_i72it_12,
._headingXl_i72it_13,
._heading2xl_i72it_14,
._heading3xl_i72it_15 {
  color: var(--token-color-text-heading-default);
}

._block_i72it_19 {
  display: block;
}

._left_i72it_23 {
  text-align: start;
}

._center_i72it_27 {
  text-align: center;
}

._right_i72it_31 {
  text-align: end;
}

._primary_i72it_35 {
  color: var(--token-color-text-primary-default);
}
._primary_i72it_35:hover {
  color: var(--token-color-text-primary-hovered);
}

._secondary_i72it_42 {
  color: var(--token-color-text-secondary);
}

._inverse_i72it_46 {
  color: var(--token-color-text-inverse);
}

._danger_i72it_50 {
  color: var(--token-color-text-danger);
}

._success_i72it_54 {
  color: var(--token-color-text-success);
}

._warning_i72it_58 {
  color: var(--token-color-text-warning-on-subtle);
}

._disabled_i72it_62 {
  color: var(--token-color-text-disabled);
}

._inherit_i72it_66 {
  color: inherit;
}

._headingSm_i72it_10 {
  font: var(--token-typography-heading-sm-bold);
}

._headingMd_i72it_11 {
  font: var(--token-typography-heading-md-bold);
}

._headingLg_i72it_12 {
  font: var(--token-typography-heading-lg-bold);
}

._headingXl_i72it_13 {
  font: var(--token-typography-heading-lg-bold);
}
@media (min-width: 30.625rem) {
  ._headingXl_i72it_13 {
    font: var(--token-typography-heading-xl-bold);
  }
}

._heading2xl_i72it_14 {
  font: var(--token-typography-heading-xl-bold);
}
@media (min-width: 30.625rem) {
  ._heading2xl_i72it_14 {
    font: var(--token-typography-heading-2xl-bold);
  }
}

._heading3xl_i72it_15 {
  font: var(--token-typography-heading-2xl-bold);
}
@media (min-width: 30.625rem) {
  ._heading3xl_i72it_15 {
    font: var(--token-typography-heading-3xl-bold);
  }
}

._bodyXs_i72it_109 {
  font: var(--token-typography-body-xs-regular);
  text-transform: uppercase;
}

._bodySm_i72it_114 {
  font: var(--token-typography-body-sm-regular);
}

._bodyMd_i72it_118 {
  font: var(--token-typography-body-md-regular);
}

._bodyLg_i72it_122 {
  font: var(--token-typography-body-lg-regular);
}

._visuallyHidden_i72it_126 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

._regular_i72it_138 {
  font-weight: var(--token-font-weight-regular);
}

._semibold_i72it_142 {
  font-weight: var(--token-font-weight-semibold);
}

._bold_i72it_146 {
  font-weight: var(--token-font-weight-bold);
}.Loader {
  display: inline-block;
  background-color: transparent;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-left-color: transparent;
  animation: icon-loader-spin 1s infinite;
  vertical-align: middle;
}

.Loader--large {
  width: 38px;
  height: 38px;
}

.Loader--dark {
  background-color: transparent;
  border-color: var(--token-color-text-primary-default);
  border-left-color: transparent;
}

.Loader--centered {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.Loader__container {
  height: 50px;
  position: relative;
  margin-bottom: 1rem;
}

.Loader__container + span {
  margin: 0 auto;
}

@keyframes icon-loader-spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  to {
    transform: rotate(360deg);
  }
}._VisuallyHidden_1maf4_1 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}._Icon_gu1ir_1 {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
}
._Icon_gu1ir_1 svg {
  height: var(--token-icon-size-default);
  width: var(--token-icon-size-default);
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

._sizeSm_gu1ir_15 svg {
  height: var(--token-icon-size-sm);
  width: var(--token-icon-size-sm);
}

._sizeMd_gu1ir_20 svg {
  height: var(--token-icon-size-md);
  width: var(--token-icon-size-md);
}

._sizeLg_gu1ir_25 svg {
  height: var(--token-icon-size-lg);
  width: var(--token-icon-size-lg);
}

._sizeXl_gu1ir_30 svg {
  height: var(--token-icon-size-xl);
  width: var(--token-icon-size-xl);
}

._size2xl_gu1ir_35 svg {
  height: var(--token-icon-size-2xl);
  width: var(--token-icon-size-2xl);
}

._colorDisabled_gu1ir_40 {
  color: var(--token-color-text-disabled);
}

._colorPrimary_gu1ir_44 {
  color: var(--token-color-icon-fill-primary-default);
}

._colorPrimary_gu1ir_44:hover {
  color: var(--token-color-icon-fill-primary-hovered);
}

._colorSecondary_gu1ir_52 {
  color: var(--token-color-icon-fill-secondary);
}

._colorInverse_gu1ir_56 {
  color: var(--token-color-icon-fill-inverse);
}

._colorDanger_gu1ir_60 {
  color: var(--token-color-icon-fill-danger);
}

._colorSuccess_gu1ir_64 {
  color: var(--token-color-icon-fill-success);
}

._colorWarningOnSubtle_gu1ir_68 {
  color: var(--token-color-icon-fill-warning-on-subtle);
}

._colorWarningOnBold_gu1ir_72 {
  color: var(--token-color-icon-fill-warning-on-bold);
}

._colorPrimaryBold_gu1ir_76 {
  color: var(--token-color-icon-fill-primary-bold);
}

._colorAccentB_gu1ir_80 {
  color: var(--token-color-accent-b-default);
}

._Svg_gu1ir_84,
._Img_gu1ir_85 {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}.Button,
input[type=button].Button {
  box-sizing: border-box;
  appearance: none !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: var(--token-spacing-2xs);
  min-height: 44px;
  padding: var(--token-spacing-inset-squish-md);
  border: 1px solid var(--token-color-background-primary-bold-default);
  border-radius: var(--token-action-button-border-radius-default);
  margin: 12px 0;
  outline: 0;
  background-color: var(--token-color-background-primary-bold-default);
  font: var(--token-typography-body-md-regular);
  color: var(--token-color-text-inverse);
  text-decoration: none;
  position: relative;
  text-transform: none;
  cursor: pointer;
}
.Button:focus:not(:disabled),
input[type=button].Button:focus:not(:disabled) {
  z-index: 2;
  outline: 3px solid var(--token-color-outline-focused-on-light);
  outline-offset: 0;
  transition: outline-width 0.1s linear;
}
.Button:focus:not(:disabled):not(:focus-visible),
input[type=button].Button:focus:not(:disabled):not(:focus-visible) {
  outline: 0;
}
.Button:hover:not(:disabled),
input[type=button].Button:hover:not(:disabled) {
  background: var(--token-color-background-primary-bold-hovered);
  border-color: var(--token-color-background-primary-bold-hovered);
  transition: background-color 0.15s ease-out;
}
.Button:disabled,
input[type=button].Button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.Button.Button--secondary,
input[type=button].Button.Button--secondary {
  background-color: #fff;
  color: var(--token-color-text-primary-default);
  border: var(--token-action-button-border-secondary-default);
}
.Button.Button--secondary:hover:not(:disabled),
input[type=button].Button.Button--secondary:hover:not(:disabled) {
  background-color: var(--token-color-background-secondary-subdued-default);
  border: var(--token-action-button-border-secondary-hovered);
}
.Button.Button--secondary:hover:not(:disabled) svg path,
input[type=button].Button.Button--secondary:hover:not(:disabled) svg path {
  stroke: var(--token-color-text-primary-hovered);
}
.Button.Button--tertiary,
input[type=button].Button.Button--tertiary {
  background-color: transparent;
  color: var(--token-color-text-primary-default);
  border: none;
}
.Button.Button--tertiary:hover:not(:disabled),
input[type=button].Button.Button--tertiary:hover:not(:disabled) {
  color: var(--token-color-text-default);
  background-color: var(--token-color-background-secondary-subdued-default);
  border: none;
}
.Button.Button--tertiary:hover:not(:disabled) svg path,
input[type=button].Button.Button--tertiary:hover:not(:disabled) svg path {
  stroke: var(--token-color-text-default);
}
.Button.Button--cta,
input[type=button].Button.Button--cta {
  background-color: var(--token-color-background-success-bold-default);
  border-color: var(--token-color-background-success-bold-default);
  border-radius: 1.5em;
}
.Button.Button--cta:hover:not(:disabled), .Button.Button--cta:focus-visible:not(:disabled),
input[type=button].Button.Button--cta:hover:not(:disabled),
input[type=button].Button.Button--cta:focus-visible:not(:disabled) {
  background-color: var(--token-color-background-success-bold-hovered);
  border-color: var(--token-color-background-success-bold-hovered);
}
.Button.Button--danger,
input[type=button].Button.Button--danger {
  background-color: var(--token-color-background-danger-bold-default);
  border-color: var(--token-color-background-danger-bold-default);
}
.Button.Button--danger:hover:not(:disabled), .Button.Button--danger:focus-visible:not(:disabled),
input[type=button].Button.Button--danger:hover:not(:disabled),
input[type=button].Button.Button--danger:focus-visible:not(:disabled) {
  background-color: var(--token-color-background-danger-bold-hovered);
  border-color: var(--token-color-background-danger-bold-hovered);
}
.Button.Button--tag,
input[type=button].Button.Button--tag {
  min-height: auto;
  padding: var(--token-spacing-inset-squish-sm);
  font: var(--token-typography-body-sm-regular);
  margin-left: 0 !important;
  border-radius: 1.5em;
  background-color: transparent;
  border: var(--token-action-button-border-tag-default);
  color: var(--token-color-text-default);
  margin: 0.2rem;
}
.Button.Button--tag:hover,
input[type=button].Button.Button--tag:hover {
  background-color: var(--token-color-background-secondary-subdued-default);
}
.Button.Button--tag.Button--active,
input[type=button].Button.Button--tag.Button--active {
  background-color: var(--token-color-background-primary-dark);
  border-color: var(--token-color-background-primary-dark);
  color: var(--token-color-text-inverse);
}
.Button.Button--sm,
input[type=button].Button.Button--sm {
  min-height: auto;
  padding: var(--token-spacing-inset-squish-sm);
  font: var(--token-typography-body-sm-regular);
}
.Button.Button--md,
input[type=button].Button.Button--md {
  padding: var(--token-spacing-inset-squish-md);
  font: var(--token-typography-body-md-regular);
}
.Button.Button--lg,
input[type=button].Button.Button--lg {
  padding: var(--token-spacing-inset-squish-lg);
  font: var(--token-typography-body-lg-regular);
}
.Button.Button--fullWidth,
input[type=button].Button.Button--fullWidth {
  width: 100%;
}
.Button.Button--loading,
input[type=button].Button.Button--loading {
  color: transparent;
}
.Button.Button--loading svg path,
input[type=button].Button.Button--loading svg path {
  stroke: transparent;
}
.Button.Button--loading .Loader,
input[type=button].Button.Button--loading .Loader {
  position: absolute;
}
.Button.Button--darkBackground:focus:not(:disabled),
input[type=button].Button.Button--darkBackground:focus:not(:disabled) {
  outline-color: var(--token-color-outline-focused-on-dark);
}
.Button.Button--darkBackground.Button--secondary,
input[type=button].Button.Button--darkBackground.Button--secondary {
  color: var(--token-action-button-color-text-secondary-on-dark-default);
  border-color: var(--token-action-button-color-text-secondary-on-dark-default);
  background-color: transparent;
}
.Button.Button--darkBackground.Button--secondary svg path,
input[type=button].Button.Button--darkBackground.Button--secondary svg path {
  stroke: currentColor;
}
.Button.Button--darkBackground.Button--secondary:hover, .Button.Button--darkBackground.Button--secondary:focus-visible:not(:disabled),
input[type=button].Button.Button--darkBackground.Button--secondary:hover,
input[type=button].Button.Button--darkBackground.Button--secondary:focus-visible:not(:disabled) {
  background-color: var(--token-color-deep-sea-blue-300);
  color: var(--token-color-text-primary-hovered);
}
.Button.Button--darkBackground.Button--secondary:active,
input[type=button].Button.Button--darkBackground.Button--secondary:active {
  background-color: var(--token-color-deep-sea-blue-400);
  color: var(--token-color-text-primary-hovered);
}
.Button.Button--slim,
input[type=button].Button.Button--slim {
  padding: 0.4rem 0.6rem;
  font-size: 0.9rem;
  margin: 0;
}
@media (max-width: 599px) {
  .Button.Button--iconOnly,
  input[type=button].Button.Button--iconOnly {
    text-size-adjust: none;
    gap: 0;
    font-size: 0;
  }
}
.Button.Button--noMargin,
input[type=button].Button.Button--noMargin {
  margin: 0;
  align-self: auto;
}

a {
  box-sizing: border-box;
}

a + .Button:not(.Button--fullWidth),
button + .Button:not(.Button--fullWidth) {
  margin-left: 12px;
}

@media print {
  .Button {
    display: none !important;
  }
}._TextInput_1uesv_1 {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  line-height: 1.8;
  border: 1px solid var(--token-color-border-secondary-default);
  background-color: #fff;
  box-sizing: border-box;
  border-radius: var(--token-border-radius-xs);
  padding: 2px 6px;
  font-family: var(--token-font-family-sans-serif);
  font-size: var(--token-font-size-md);
  color: #000;
  -webkit-appearance: none;
}
._TextInput_1uesv_1:focus {
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--token-color-border-primary-hovered);
  outline: none;
}
._TextInput_1uesv_1::placeholder {
  color: var(--token-color-text-secondary);
  font-family: var(--token-font-family-sans-serif);
}
._TextInput_1uesv_1:read-only {
  color: var(--token-color-text-disabled);
}

._TextInput--size-small_1uesv_29 {
  align-self: start;
  max-width: 6em;
}

._TextInputWithIconLeft_1uesv_34 {
  padding-left: var(--token-spacing-2xl);
}

._TextInputWithIconRight_1uesv_38 {
  padding-right: var(--token-spacing-2xl);
}

._TextInputError_1uesv_42 {
  border: 1px solid var(--token-color-danger-default);
}

._TextInputSubtle_1uesv_46 {
  border-color: var(--token-color-neutral-500);
}

._TextInputContainer_1uesv_50 {
  width: 100%;
  position: relative;
}
._TextInputContainer_1uesv_50 ._IconLeft_1uesv_54 {
  position: absolute;
  left: var(--token-spacing-xs);
  top: var(--token-spacing-xs);
}
._TextInputContainer_1uesv_50 ._IconRight_1uesv_59 {
  position: absolute;
  right: var(--token-spacing-xs);
  top: var(--token-spacing-xs);
}._actions_4aqys_1 .Button {
  margin-bottom: 0;
  margin-top: 0;
}._PageLayout_1h1ed_1 {
  max-width: 1100px;
  width: 100%;
  margin: var(--token-spacing-xl) auto;
}._DataTable_r2em6_1 {
  min-height: 300px;
  background-color: transparent !important;
}
._DataTable_r2em6_1 .rdt_TableCell {
  white-space: nowrap;
  padding: 0;
}
._DataTable_r2em6_1 .rdt_TableCol {
  padding: 0;
}
._DataTable_r2em6_1 .rdt_TableRow button[data-testid*=expander-button] {
  padding: 1.5rem 0;
}
._DataTable_r2em6_1 .rdt_TableHeadRow {
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: var(--token-border-radius-sm) var(--token-border-radius-sm) 0 0;
  background: var(--background-secondary-subtle, #f9fafb);
  border: 1px solid var(--token-color-border-default);
  gap: 1rem;
}
._DataTable_r2em6_1 .rdt_TableRow {
  border: 1px solid var(--token-color-border-default);
  border-top: 0;
  gap: 1rem;
}
._DataTable_r2em6_1 .rdt_TableRow:last-child {
  border-radius: 0 0 var(--token-border-radius-sm) var(--token-border-radius-sm);
}
._DataTable_r2em6_1 .rdt_TableCell:first-child {
  padding-left: var(--token-spacing-md);
  padding-right: calc(-1 * var(--token-spacing-md));
}
._DataTable_r2em6_1 .rdt_ExpanderRow {
  border: 1px solid var(--token-color-border-default);
  border-top: 0;
}
._DataTable_r2em6_1 .Loader {
  margin-top: 75px;
}._Link_1mfgn_1 {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  gap: var(--token-spacing-3xs);
  text-decoration: var(--token-text-decoration-underline);
  text-decoration-color: var(--token-color-text-default);
}
._Link_1mfgn_1:hover {
  text-decoration: var(--token-text-decoration-underline);
  text-decoration-color: var(--token-color-text-default);
  text-decoration-thickness: var(--token-border-width-2x);
}

._color--primary_1mfgn_15 {
  text-decoration-color: var(--token-color-text-primary-default);
}
._color--primary_1mfgn_15:hover {
  text-decoration-color: var(--token-color-text-primary-hovered);
}
._color--primary_1mfgn_15:hover svg path {
  stroke: var(--token-color-text-primary-hovered);
}
._color--monochrome_1mfgn_24 {
  text-decoration-color: var(--token-color-text-default);
}
._color--inverse_1mfgn_27 {
  text-decoration-color: var(--token-color-text-inverse);
}
._color--inverse_1mfgn_27:hover {
  text-decoration-color: var(--token-color-text-inverse);
}

._underline--none_1mfgn_34, ._underline--hover_1mfgn_34 {
  text-decoration: none;
}
._underline--none_1mfgn_34:hover {
  text-decoration: none;
}
._underline--hover_1mfgn_34:hover, ._underline--hover_1mfgn_34:focus-visible {
  text-decoration: var(--token-text-decoration-underline);
  text-decoration-color: var(--token-color-text-default);
}
._underline--hover_1mfgn_34:hover._color--primary_1mfgn_15, ._underline--hover_1mfgn_34:focus-visible._color--primary_1mfgn_15 {
  text-decoration-color: var(--token-color-text-primary-hovered);
}
._underline--hover_1mfgn_34:hover._color--monochrome_1mfgn_24, ._underline--hover_1mfgn_34:focus-visible._color--monochrome_1mfgn_24 {
  text-decoration-color: var(--token-color-text-default);
}
._underline--hover_1mfgn_34:hover._color--inverse_1mfgn_27, ._underline--hover_1mfgn_34:focus-visible._color--inverse_1mfgn_27 {
  text-decoration-color: var(--token-color-text-inverse);
}

._button_1mfgn_54 {
  background: none;
  border: none;
  appearance: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
}._Popover_7ngzk_1 {
  border-radius: var(--token-border-radius-sm);
  background-color: #fff;
  box-shadow: var(--token-elevation-overlay-shadow);
  width: max-content;
  position: absolute;
  top: 100%;
  z-index: 100;
  overflow-y: auto;
}

._PopoverLeft_7ngzk_12 {
  left: 0;
}

._PopoverRight_7ngzk_16 {
  right: 0;
}

._PopoverTrigger_7ngzk_20 {
  appearance: none;
  border: none;
  cursor: pointer;
  background: none;
  overflow: visible;
  padding: 0;
}

._fullWidth_7ngzk_29,
._fullWidth_7ngzk_29 ._Popover_7ngzk_1 {
  width: 100%;
}._SortableHeader_35vk9_1 {
  font-size: 0.8rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-basis: 0;
  max-width: 100%;
  min-width: 100px;
}
._SortableHeader_35vk9_1:first-child {
  padding-left: var(--token-spacing-md);
  padding-right: calc(-1 * var(--token-spacing-md));
}

._inner_35vk9_16 {
  display: flex;
  min-width: 50px;
}._ShowAsTable_lxy4t_1 {
  display: none;
}
@media (min-width: 600px) {
  ._ShowAsTable_lxy4t_1 {
    display: block;
  }
}

@media (min-width: 600px) {
  ._ShowAsCards_lxy4t_11 {
    display: none;
  }
}

._tableView_lxy4t_16 {
  display: flex;
  font-size: 0.9rem;
  box-sizing: border-box;
}
._tableView_lxy4t_16 * {
  box-sizing: inherit;
}

._tableView_main_lxy4t_25 {
  min-width: 70%;
  flex: 1;
}

._tableView_right_lxy4t_30,
._tableView_left_lxy4t_31 {
  max-width: 270px;
  flex-grow: 1;
}
._tableView_right_lxy4t_30 input:not([type=checkbox], [type=radio]),
._tableView_right_lxy4t_30 select,
._tableView_left_lxy4t_31 input:not([type=checkbox], [type=radio]),
._tableView_left_lxy4t_31 select {
  border-radius: 0;
  height: 40px;
  border: 1px solid #dbdbdb;
  width: 100%;
  padding: 10px;
}
._tableView_right_lxy4t_30 ._Tile_lxy4t_45,
._tableView_left_lxy4t_31 ._Tile_lxy4t_45 {
  margin-bottom: 1rem;
}
._tableView_right_lxy4t_30 h3,
._tableView_left_lxy4t_31 h3 {
  font-size: 1rem;
}

._tableView_right_lxy4t_30 {
  margin-left: 1.5rem;
}

._tableView_left_lxy4t_31 {
  margin-right: 1.5rem;
  border-right: 1px solid var(--token-color-border-default);
  padding-right: 1rem;
}

.tableView_inputWithClear {
  position: relative;
}
.tableView_inputWithClear .react-datepicker-wrapper {
  width: 100%;
}
.tableView_inputWithClear input {
  padding-right: 30px;
}
.tableView_inputWithClear > button {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 0;
  background: transparent;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0.25rem;
}
.tableView_inputWithClear > button svg {
  width: 12px;
  height: 12px;
  fill: #82888a;
}._ModalOverlay_8bskf_1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

._Modal_8bskf_1 {
  min-width: 150px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  line-height: 1.3;
  max-width: var(--max-width, auto);
  max-height: 90vh;
  max-height: 90dvh;
  border-radius: var(--token-border-radius-sm);
}

._Container_8bskf_27 {
  padding: 2rem;
  height: 100%;
  max-height: 90vh;
  max-height: 90dvh;
  overflow-y: auto;
}

._Inner_8bskf_35 {
  height: 100%;
  width: 100%;
}

._Close_8bskf_40 {
  width: 18px;
  height: 18px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 35px;
}
._Close_8bskf_40::before, ._Close_8bskf_40::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #444; /* Adjust color if needed */
  transform-origin: center;
}
._Close_8bskf_40::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
._Close_8bskf_40::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

._FullWidth_8bskf_68 {
  height: 60vh;
  width: 75vw;
}
@media (min-width: 800px) {
  ._FullWidth_8bskf_68 {
    height: 80vh;
    width: 90vw;
  }
}

@media (max-width: 600px) {
  ._MobileFullScreenCard_8bskf_80 {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: none;
  }
}.SidebarModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 1s;
  box-sizing: border-box;
  z-index: 100;
}

.SidebarModal .SidebarModal__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
}

.SidebarModal__inner {
  background: #fff;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 80;
  width: 100%;
  overflow: auto;
  scroll-padding: 2rem;
}
@media (min-width: 700px) {
  .SidebarModal__inner {
    width: 80%;
  }
}
@media (min-width: 800px) {
  .SidebarModal__inner {
    width: 65%;
  }
}
@media (min-width: 1000px) {
  .SidebarModal__inner {
    width: 50%;
  }
}

.SidebarModal__inner .TileView {
  padding: 2rem 1.5rem;
  margin: 0;
  min-height: 100%;
}

.SidebarModal__inner .Tile {
  padding: 1.5rem 1rem;
}
@media (min-width: 700px) {
  .SidebarModal__inner .Tile {
    padding: 2rem 1.5rem;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
.SidebarModal__inner {
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-duration: 0.3s;
  animation-delay: 150ms;
}

.SidebarModal--enter .SidebarModal__inner {
  animation-name: slideIn;
}

.SidebarModal--exit .SidebarModal__inner {
  animation-name: slideOut;
}

.SidebarModal--exit {
  background: rgba(0, 0, 0, 0);
}._RadioBlocks_51cvv_1 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--radio-blocks-gap);
  align-items: center;
}
@media (min-width: 600px) {
  ._RadioBlocks_51cvv_1 {
    flex-wrap: nowrap;
  }
}
._RadioBlocks_51cvv_1._RadioBlocksHorizontal_51cvv_15 {
  flex-direction: row;
}
._RadioBlocks_51cvv_1._RadioBlocksVertical_51cvv_18 {
  max-width: 500px;
  margin: 0 auto;
}
._RadioBlocks_51cvv_1._RadioBlocksWrap_51cvv_22 {
  flex-wrap: wrap;
}
._RadioBlocks_51cvv_1._RadioBlocksJustify_51cvv_25 {
  justify-content: center;
}
._RadioBlocks_51cvv_1._RadioBlocksLine_51cvv_28 {
  gap: unset;
}
._RadioBlocks_51cvv_1._RadioBlocksFullWidth_51cvv_31 {
  width: 100%;
  max-width: none;
}

._RadioBlock_51cvv_1 {
  cursor: pointer !important;
  background-color: #fff;
  padding: var(--token-spacing-sm) var(--token-spacing-default);
  width: 100%;
  border-radius: var(--token-border-radius-sm);
  box-shadow: inset 0 0 0 1px var(--token-color-border-secondary-default);
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  position: relative;
}
@media (min-width: 600px) {
  ._RadioBlock_51cvv_1 {
    width: initial;
    flex-shrink: 1;
    flex-grow: 0;
  }
}
._RadioBlock_51cvv_1 input {
  appearance: none;
  position: absolute;
  height: 1px !important;
  width: 1px !important;
  right: 5px;
  top: 5px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
._RadioBlock_51cvv_1._RadioBlockFullWidth_51cvv_65 {
  width: 100%;
}
._RadioBlock_51cvv_1._RadioBlockError_51cvv_68 {
  box-shadow: inset 0 0 0 1px var(--token-color-danger-default);
}
._RadioBlock_51cvv_1._RadioBlockError_51cvv_68 circle {
  stroke: var(--token-color-danger-default);
}
._RadioBlock_51cvv_1:hover {
  background-color: var(--token-color-background-primary-subtle);
}
._RadioBlock_51cvv_1:hover._RadioBlockError_51cvv_68 {
  box-shadow: inset 0 0 0 1px var(--token-color-danger-hovered);
}
._RadioBlock_51cvv_1:has(:focus-visible) {
  box-shadow: 0 0 0 3px var(--token-color-outline-focused-on-light);
}
@supports not selector(:has(*)) {
  ._RadioBlock_51cvv_1:focus-within {
    box-shadow: 0 0 0 3px var(--token-color-outline-focused-on-light);
  }
}

._RadioBlockHead_51cvv_89 {
  display: flex;
  justify-content: space-between;
  gap: var(--token-spacing-2xl);
  align-items: center;
}
._RadioBlockHead_51cvv_89 svg {
  flex-shrink: 0;
}

._RadioBlockChecked_51cvv_99 {
  background-color: var(--token-color-background-primary-subtle);
  box-shadow: inset 0 0 0 2px var(--token-color-border-primary-default);
}
._RadioBlockChecked_51cvv_99:hover {
  background-color: var(--token-color-blue-200);
}

._RadioBlockReadOnly_51cvv_107:not(._RadioBlockChecked_51cvv_99) {
  cursor: initial !important;
  background-color: var(--token-color-background-secondary-subtle);
  box-shadow: inset 0 0 0 1px var(--token-color-neutral-300);
}
._RadioBlockReadOnly_51cvv_107:not(._RadioBlockChecked_51cvv_99) circle {
  stroke: var(--token-color-neutral-300);
}

._RadioBlockLine_51cvv_116 {
  border-radius: 0;
  border: 1px solid var(--token-color-border-secondary-default);
  border-right: 0;
  box-shadow: none;
}
._RadioBlockLine_51cvv_116._RadioBlockChecked_51cvv_99 {
  box-shadow: inset 0 0 0 1px var(--token-color-border-secondary-default);
}
._RadioBlockLine_51cvv_116:first-of-type {
  border-radius: var(--token-border-radius-sm) 0 0 var(--token-border-radius-sm);
}
._RadioBlockLine_51cvv_116:last-of-type {
  border-radius: 0 var(--token-border-radius-sm) var(--token-border-radius-sm) 0;
  border-right: 1px solid var(--token-color-border-secondary-default);
}
._RadioBlockLine_51cvv_116:first-of-type:last-of-type {
  border-radius: var(--token-border-radius-sm);
}

._Blocks_51cvv_136 svg {
  display: none;
}.ToggleButton {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 10px;
  position: relative;
}
.ToggleButton:hover:not(.ToggleButton--checked) .ToggleButton-switch {
  background: var(--token-color-background-secondary-subdued-default);
  border-color: var(--token-color-border-secondary-hovered);
}
.ToggleButton:hover:not(.ToggleButton--checked) .ToggleButton-switch::after {
  border-color: var(--token-color-border-secondary-hovered);
}

.ToggleButton--reversed .ToggleButton__container {
  flex-direction: row-reverse;
}

.ToggleButton--reversed .ToggleButton-label {
  margin-left: 7px;
  margin-right: 0;
}

.ToggleButton:focus-within:not(:focus-visible) .ToggleButton-switch {
  outline: 1px dashed black;
  outline: -webkit-focus-ring-color auto 1px;
}

.ToggleButton--checked .ToggleButton-switch {
  border-color: var(--token-color-background-primary-dark);
  background: var(--token-color-background-primary-dark);
}
.ToggleButton--checked .ToggleButton-switch::after {
  border-color: var(--token-color-background-primary-dark);
  left: 48%;
}

.ToggleButton__container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ToggleButton-label {
  color: #00556e;
  margin: 0 7px 0 0;
  font-size: 1rem;
  font-weight: normal;
}

.ToggleButton-input {
  cursor: pointer;
  position: absolute;
  left: initial;
  right: 5px;
  z-index: 1;
  opacity: 0;
}

.ToggleButton--reversed .ToggleButton-input {
  right: initial;
  left: 5px;
}

.ToggleButton-switch {
  width: 32px;
  height: 16px;
  box-sizing: content-box;
  cursor: pointer;
  padding: 4px;
  background: var(--token-color-background-secondary-subtle);
  border: 1px solid var(--token-color-neutral-600);
  border-radius: 999px;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  transition-property: background, border-color;
}
.ToggleButton-switch::after {
  content: "";
  position: relative;
  left: 0;
  top: -0.5px;
  display: block;
  width: 50%;
  height: 100%;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  transition-property: left, border-color;
  background: var(--token-color-background-inverse-default);
  border: 1px solid var(--token-color-neutral-600);
  border-radius: 50%;
}

.ToggleButton--md .ToggleButton-switch {
  width: 40px;
  height: 20px;
}._DateInput_1pqr8_1 {
  max-width: 100%;
  line-height: 1.8;
  border: 1px solid var(--token-color-border-secondary-default);
  background-color: #fff;
  box-sizing: border-box;
  border-radius: var(--token-border-radius-xs);
  padding: 2px 6px;
  font-family: var(--token-font-family-sans-serif);
  font-size: var(--token-font-size-md);
  color: #000;
  -webkit-appearance: none;
}
._DateInput_1pqr8_1:focus {
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--token-color-border-primary-hovered);
  outline: none;
}
._DateInput_1pqr8_1::placeholder {
  color: var(--token-color-text-secondary);
  font-family: var(--token-font-family-sans-serif);
}
._DateInput_1pqr8_1:read-only {
  color: var(--token-color-text-disabled);
}

._DateInput--size-small_1pqr8_27 {
  align-self: start;
  max-width: 6em;
}

._DateInputWithIconLeft_1pqr8_32 {
  padding-left: var(--token-spacing-2xl);
}

._DateInputWithIconRight_1pqr8_36 {
  padding-right: var(--token-spacing-2xl);
}

._DateInputError_1pqr8_40 {
  border: 1px solid var(--token-color-danger-default);
}

._DateInputSubtle_1pqr8_44 {
  border-color: var(--token-color-neutral-500);
}

._DateInputContainer_1pqr8_48 {
  width: 100%;
  position: relative;
}
._DateInputContainer_1pqr8_48 ._IconLeft_1pqr8_52 {
  position: absolute;
  left: var(--token-spacing-xs);
  top: var(--token-spacing-xs);
}
._DateInputContainer_1pqr8_48 ._IconRight_1pqr8_57 {
  position: absolute;
  right: var(--token-spacing-xs);
  top: var(--token-spacing-xs);
  background-color: #fff;
}._DynamicInfoText_zvjd4_1 {
  color: #00556e;
  font-style: italic;
  font-weight: 600;
}
._DynamicInfoText_zvjd4_1 a {
  color: inherit;
}._wrapper_kb2qa_1 {
  padding: 1.5rem 0 1rem 0;
}
._wrapper_kb2qa_1 ._bar_kb2qa_4 {
  width: 100%;
  height: 20px;
  border: 1px solid #484848;
  border-radius: 8px;
  max-width: 600px;
  margin-bottom: 0.5rem;
  overflow: hidden;
}
._wrapper_kb2qa_1 ._progress_kb2qa_13 {
  background-color: #018927;
  height: 100%;
}

._wrapperBeta_kb2qa_18 ._barBeta_kb2qa_18 {
  width: 100%;
  height: 8px;
  border: none;
  border-radius: var(--token-border-radius-sm);
  background-color: var(--token-color-background-secondary-subdued-default);
  overflow: hidden;
}
@media (min-width: 1000px) {
  ._wrapperBeta_kb2qa_18 ._barBeta_kb2qa_18 {
    max-width: 500px;
  }
}
._wrapperBeta_kb2qa_18 ._progressBeta_kb2qa_31 {
  background-color: var(--token-color-success-default);
  height: 100%;
}._avatar_9h4ty_1 {
  background-color: var(--token-color-deep-sea-blue-300);
  border-radius: 2.75rem;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  user-select: none;
  transition: box-shadow 0.4s ease-in-out;
  position: relative;
}
button:hover ._avatar_9h4ty_1 {
  box-shadow: 0 0 0 4px var(--token-color-deep-sea-blue-500);
}

._active_9h4ty_16 {
  box-shadow: 0 0 0 4px var(--token-color-deep-sea-blue-500);
}

._textContainer_9h4ty_20 {
  margin: 0 auto;
}._Fieldset_1qbt2_1 {
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: contents;
}._DesktopViewRow_1cw52_1 {
  border-bottom: 1px solid var(--token-color-border-default);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--token-spacing-md);
  align-items: baseline;
  padding: var(--token-spacing-inset-xl);
  padding-left: var(--token-spacing-md);
}
._DesktopViewRow_1cw52_1:first-child {
  padding-top: 0;
}

._DesktopViewRowActions_1cw52_14 {
  display: flex;
  margin-left: auto;
}
._DesktopViewRowActions_1cw52_14 .Button {
  margin: 0 !important;
}

._DesktopView_1cw52_1 {
  display: none;
}

._EmptyState_1cw52_26 {
  background-color: var(--token-color-background-secondary-subtle);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--token-spacing-inset-2xl);
}

._modalContainer_1cw52_34 {
  max-width: 600px;
}

@container questionnaire (min-width: 600px) {
  ._DesktopView_1cw52_1 {
    display: block;
  }
  ._MobileView_1cw52_42 {
    display: none;
  }
}
@media (min-width: 650px) {
  ._modalContainer_1cw52_34 {
    width: 500px;
  }
}._DemographicQuestionInput_4vyay_1 {
  display: flex;
  flex-direction: column;
  gap: var(--token-spacing-sm);
}

._Demographics_4vyay_7 {
  display: flex;
  flex-direction: column;
  font-weight: var(--token-font-weight-semibold);
  border-radius: var(--token-border-radius-xxs);
  background-color: var(--token-color-background-secondary-subtle);
  padding: var(--token-spacing-lg);
  gap: var(--token-spacing-3xs);
}

._InfoPanelAuthoring_4vyay_17 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: var(--token-border-radius-sm);
  background-color: var(--token-color-background-secondary-subtle);
  padding: var(--token-spacing-sm);
  gap: var(--token-spacing-3xs);
}

._InfoPanelAnswering_4vyay_27 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: var(--token-border-radius-sm);
  background-color: var(--token-color-blue-100);
  padding: var(--token-spacing-sm);
  gap: var(--token-spacing-3xs);
}

._DemographicsFieldAuthoring_4vyay_37 {
  word-wrap: break-word;
  color: var(--token-color-secondary-default);
}
._DemographicsFieldAuthoring_4vyay_37 > h4 {
  font-weight: var(--token-font-weight-bold);
  margin: 0.5rem 0 0.5rem 0;
}
._DemographicsFieldAuthoring_4vyay_37 > p {
  margin: 0.5rem 0 1.5rem 0;
  line-height: 1.75rem;
}

._DemographicsFieldAnswering_4vyay_50 {
  word-wrap: break-word;
  color: var(--token-color-text-heading-default);
}
._DemographicsFieldAnswering_4vyay_50 > h4 {
  font-weight: var(--token-font-weight-bold);
  margin: 1rem 0 0.5rem 0;
}
._DemographicsFieldAnswering_4vyay_50 > p {
  margin: 0.5rem 0 1.5rem 0;
  line-height: 1.75rem;
}._MeasureInput_rnuu2_1 {
  all: unset;
}
._MeasureInput_rnuu2_1 button {
  margin: 0 !important;
}._ClinicalPageSummary_3dqi5_1 {
  background-color: var(--token-color-background-warning-subtle) !important;
  border: solid 2px var(--token-color-warning-default);
}
._ClinicalPageSummary_3dqi5_1 li {
  margin-bottom: var(--token-spacing-xs);
}

._reviewDisclosures_3dqi5_9 {
  margin-bottom: var(--token-spacing-xs);
}

._DisclosuresList_3dqi5_13 {
  padding-left: 16px;
  margin: 0;
}@container questionnaire (min-width: 800px) {
  ._ClinicianLayout_cc2zd_2 {
    display: grid;
    gap: var(--token-spacing-xl);
    grid-template-columns: 1fr 1fr;
  }
}
@supports not (container-name: questionnaire) {
  @media (min-width: 800px) {
    ._ClinicianLayout_cc2zd_2 {
      display: grid;
      gap: var(--token-spacing-xl);
      grid-template-columns: 1fr 1fr;
    }
  }
}
._ClinicianLayout_cc2zd_2 > *,
._ClinicianLayout_cc2zd_2 > fieldset > * {
  grid-column: 1;
}
._ClinicianLayout_cc2zd_2 [data-clinician] {
  display: block !important;
  grid-column: 2;
  background: var(--token-color-purple-100);
  border-radius: var(--token-border-radius-sm);
  white-space: pre-line;
}
._ClinicianLayout_cc2zd_2 > [data-clinician] {
  padding: var(--token-spacing-xl);
}._Container_1w6pj_1 {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  container: questionnaire/inline-size;
}

._Tile_1w6pj_8 {
  border: 1px solid var(--token-color-neutral-300);
  border-radius: var(--token-border-radius-sm);
  background: #fff;
  padding: var(--token-spacing-xl);
}._TextareaInput_ptjp1_1 {
  line-height: 1.8;
  box-sizing: border-box;
  border: 1px solid var(--token-color-border-secondary-default);
  background-color: #fff;
  border-radius: var(--token-border-radius-xs);
  font: var(--token-typography-body-md-regular);
  padding: var(--token-spacing-inset-squish-sm);
  color: #000;
  -webkit-appearance: none;
  resize: vertical;
  min-height: 50px;
  max-height: 30vh;
  width: 100%;
}
._TextareaInput_ptjp1_1:focus {
  box-shadow: none;
  outline: none;
}
._TextareaInput_ptjp1_1:read-only {
  color: #a1a1a1;
}

._TextareaInput_hint_ptjp1_24 {
  color: #555;
  padding-bottom: 0.5rem;
}._Select_uxr0s_1 {
  position: relative;
}

._trigger_uxr0s_5 [data-is-popover=true] {
  color: var(--token-action-button-color-icon-fill-secondary-on-dark-hovered);
  width: 100%;
  display: flex;
  gap: var(--token-spacing-3xs);
  justify-content: space-between;
  font-size: var(--token-font-size-md);
}

._trigger_uxr0s_5 {
  margin: 0;
  width: 100%;
}

._placeholder_uxr0s_19 {
  color: var(--token-color-text-secondary);
}

._active_uxr0s_23 ._chevron_uxr0s_23 svg {
  transform: rotate(180deg);
}

._chevron_uxr0s_23 svg {
  transform: rotate(0);
  transition: transform 0.5s ease;
}

._marker_uxr0s_32 {
  line-height: 16px;
  border-radius: 8px;
  background: var(--token-action-button-color-icon-fill-secondary-on-dark-hovered);
  color: #fff;
  padding: 0 5px;
}

._option_uxr0s_40 {
  display: block;
  background: none;
  border: none;
  border-radius: var(--token-spacing-3xs);
  padding: var(--token-spacing-inset-xs);
  font-size: var(--token-font-size-md);
  width: 100%;
  text-align: left;
}
._option_uxr0s_40:hover {
  cursor: pointer;
  background: var(--hoverColorToken);
}div._container_c5bpw_1 > button {
  width: 40px;
  height: 40px;
  border-radius: var(--token-border-radius-xs);
  display: flex;
  align-items: center;
  justify-content: center;
}
div._container_c5bpw_1 > button:hover {
  cursor: pointer;
  background: var(--token-color-background-secondary-subdued-hovered);
}
div._container_c5bpw_1 div[class*=_Popover_] {
  overflow: hidden;
  padding: var(--token-spacing-xs) 0;
}

._open_c5bpw_18 > button {
  background: var(--token-color-background-secondary-subdued-hovered);
}

._options_c5bpw_22 {
  display: flex;
  flex-direction: column;
}

._option_c5bpw_22 {
  width: 100%;
  background: none;
  border: none;
  padding: var(--token-spacing-xs) var(--token-spacing-md);
  display: flex;
}
._option_c5bpw_22:hover {
  background: var(--token-color-background-secondary-subdued-hovered);
}
._option_c5bpw_22:disabled span {
  cursor: not-allowed;
  color: var(--token-color-text-disabled) !important;
}
._option_c5bpw_22:disabled path {
  stroke: var(--token-color-text-disabled);
}._field_12mja_1 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--token-spacing-2xs);
  border: 1px solid var(--token-color-border-secondary-default);
  font-size: var(--token-font-size-md);
  border-radius: var(--token-border-radius-xs);
  padding: var(--token-spacing-3xs) var(--token-spacing-5xl) var(--token-spacing-3xs) var(--token-spacing-xs);
}
._field_12mja_1._focused_12mja_12 {
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--token-color-border-primary-hovered);
  outline: none;
}
._field_12mja_1._focused_12mja_12 ._chevron_12mja_17 svg {
  transform: rotate(180deg);
}
._field_12mja_1._focused_12mja_12 ._clearBtn_12mja_20 {
  display: block;
}
._field_12mja_1:hover ._clearBtn_12mja_20 {
  display: block;
}

._selectedValue_12mja_27 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--token-color-primary-dark);
  padding: var(--token-spacing-2xs) var(--token-spacing-sm);
  gap: var(--token-spacing-2xs);
  border-radius: var(--token-spacing-2xl);
  color: #fff;
}
._selectedValue_12mja_27 ._clearBtn_12mja_20 {
  display: block;
  position: inherit;
  width: max-content;
}
._selectedValue_12mja_27 ._clearBtn_12mja_20:hover {
  cursor: pointer;
  background: none;
}

._actionIcon_12mja_47 {
  background: none;
  border: none;
  border-radius: var(--token-spacing-3xs);
  padding: var(--token-spacing-3xs);
}
._actionIcon_12mja_47:hover {
  cursor: pointer;
  background-color: var(--token-color-neutral-100);
}

._chevron_12mja_17 {
  position: absolute;
  right: var(--token-spacing-sm);
  top: calc(50% - 9px - var(--token-spacing-3xs));
}
._chevron_12mja_17 svg {
  transform: rotate(0);
  transition: transform 0.5s ease;
}

._searchInput_12mja_68 {
  line-height: 1.8;
  border: none;
  flex-grow: 1;
  font-size: inherit;
}
._searchInput_12mja_68:focus, ._searchInput_12mja_68:focus-visible {
  border: none;
  outline: none;
}

._dropdown_12mja_79 {
  position: absolute;
  z-index: 10;
  border-radius: 16px;
  background: var(--token-color-white);
  box-shadow: 0 1px 1px 0 rgba(0, 29, 44, 0.15), 0 8px 12px 0 rgba(0, 29, 44, 0.31);
  animation: _fadeIn_12mja_1 0.2s linear forwards;
  padding: var(--token-spacing-xl) 0;
  width: 100%;
  top: calc(100% + var(--token-spacing-3xs));
  left: 0;
}
._dropdown_12mja_79 ::-webkit-scrollbar {
  display: none;
}

._suggestionList_12mja_95 {
  max-height: 240px;
  overflow: auto;
}

._suggestItem_12mja_100 {
  padding: var(--token-spacing-2xs) var(--token-spacing-xl);
  width: 100%;
  display: flex;
  justify-content: left;
  border: none;
  background: none;
  align-items: center;
  gap: var(--token-spacing-sm);
}
._suggestItem_12mja_100 ._checkIcon_12mja_110 {
  margin-left: var(--token-spacing-2xs);
}
._suggestItem_12mja_100 > label {
  display: block;
  width: 100%;
}
._suggestItem_12mja_100 input:disabled + label {
  color: var(--token-color-text-disabled);
}
._suggestItem_12mja_100:not(._suggestItemDisabled_12mja_120):hover {
  cursor: pointer;
  background-color: var(--token-color-neutral-100);
}
._suggestItem_12mja_100:not(._suggestItemDisabled_12mja_120) > label:hover {
  cursor: pointer;
  background-color: var(--token-color-neutral-100);
}

._clearBtn_12mja_20 {
  display: none;
  position: absolute;
  top: calc(50% - 9px - var(--token-spacing-3xs));
  right: var(--token-spacing-3xl);
}

._dropdownInfo_12mja_136 {
  width: 100%;
  display: flex;
  justify-content: center;
}._fieldset_1yhur_1 {
  all: unset;
}._ContainerQuery_1rjp5_1 {
  container: layoutGrid/inline-size;
}

._LayoutGrid_1rjp5_5 {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--layout-grid-gap) 0;
}
._LayoutGrid_1rjp5_5 > div {
  grid-column-start: 1;
  grid-column-end: none;
}
@container layoutGrid (min-width: 600px) {
  ._LayoutGrid_1rjp5_5 {
    grid-template-columns: repeat(var(--layout-grid-columns), 1fr);
    gap: var(--layout-grid-gap);
  }
  ._LayoutGrid_1rjp5_5 > div {
    grid-column-start: var(--group-column-start);
    grid-column-end: span var(--group-column-span);
  }
}._Badge_g5xm0_1 {
  border-radius: var(--token-border-radius-xxs);
}

._BadgeSm_g5xm0_5,
._BadgeMd_g5xm0_6 {
  border: 1px solid;
  display: inline-flex;
  align-items: center;
  gap: var(--token-spacing-3xs);
  border-radius: var(--token-border-radius-xxs);
  flex-shrink: 0;
}
._BadgeSm_g5xm0_5 svg path,
._BadgeMd_g5xm0_6 svg path {
  stroke: currentColor;
}

._BadgeSm_g5xm0_5 {
  padding: var(--token-spacing-inset-squish-2xs);
}

._BadgeMd_g5xm0_6 {
  padding: var(--token-spacing-inset-squish-xs);
}

._BadgeBold_g5xm0_27 {
  border: none;
  color: var(--token-color-text-inverse) !important;
}

._BadgeHighlight_g5xm0_32 {
  border: none;
  font-weight: var(--token-font-weight-semibold);
}